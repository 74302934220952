<template>
  <div class="mt-12">
    <div class="d-flex">
      <div class="d-flex aside-bar">
        <v-col>
          <div
            class="d-flex flex-column flex-wrap"
            v-for="(filterItem, i) in filters"
            :key="i"
          >
            <h3>
              {{
                $vuetify.lang.t(
                  `$vuetify.main.psychologistDashboard.specialization.${i}`
                )
              }}
            </h3>
            <template v-for="(item, i) in filterItem">
              <v-chip :key="i" class="mt-2" @click="handleFilters(i, $event)">
                {{ item }}
              </v-chip>
            </template>
          </div>
        </v-col>
      </div>

      <div class="d-flex flex-wrap justify-space-between" v-if="isLoad">
        <v-col cols="12" md="4" v-for="n in 8" :key="n">
          <v-skeleton-loader
            width="374"
            height="490"
            :key="n.id"
            v-bind="attrs"
            type="card-avatar, article, actions"
          ></v-skeleton-loader>
        </v-col>
      </div>
      <div
        class="d-flex flex-wrap justify-space-around full-width"
        v-if="!isLoad"
      >
        <v-row class="mt-10" justify="center" v-if="psychologists.length === 0">
          <h1>
            {{
              $vuetify.lang.t(
                "$vuetify.main.patientDashboard.ourPsychologists.oops"
              )
            }}
          </h1>
        </v-row>
        <template v-for="psychologist in psychologists">
          <v-card
            :key="psychologist.id"
            v-if="!isLoad"
            :loading="loading"
            class="mx-auto my-12 d-flex justify-space-between flex-column"
            max-width="374"
            height="640"
          >
            <template slot="progress">
              <v-progress-linear
                color="primary"
                height="5"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-img
              class="ma-5 border-rounded align-self-center"
              max-height="250"
              width="250"
              :src="psychologist.photo"
            ></v-img>

            <v-card-title class="align-self-start">{{
              psychologist.name + " " + psychologist.surname
            }}</v-card-title>

            <v-card-text>
              <v-row align="center" class="mx-0">
                <template v-if="getUserRole !== 'patient'">
                  <v-rating
                    :value="4.5"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>

                  <div class="grey--text ms-4">4.5 (413)</div>
                </template>
              </v-row>

              <div class="my-4 text-subtitle-1">
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.patientDashboard.ourPsychologists.workExperience"
                  )
                }}
                {{ psychologist.experience_ages }}
                {{ setConsultingExperienceText(psychologist.experience_ages) }}
              </div>

              <div>
                {{ psychologist.about_info | shorten(5) }}
                <showPsyhDescriptionModal
                  :user="{
                    userName: psychologist.name,
                    aboutInfo: psychologist.about_info,
                  }"
                >
                  <span class="text__primary"
                    >{{
                      $vuetify.lang.t(
                        "$vuetify.main.patientDashboard.ourPsychologists.showFully"
                      )
                    }}
                  </span>
                </showPsyhDescriptionModal>
              </div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>{{
              $vuetify.lang.t(
                "$vuetify.main.patientDashboard.ourPsychologists.chooseDateTime"
              )
            }}</v-card-title>

            <div class="align-self-start">
              <showDateTimePicker
                :handle-user-select-data="savePsychologistAndTimeSlot"
                :default-value="psychologist"
                >{{
                  $vuetify.lang.t(
                    "$vuetify.main.patientDashboard.ourPsychologists.chooseTime"
                  )
                }}</showDateTimePicker
              >
            </div>

            <v-card-actions class="justify-end">
              <v-btn
                color="primary"
                text
                @click="saveNewPsychologistOnServer(psychologist.id)"
              >
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.patientDashboard.ourPsychologists.book"
                  )
                }}
                <span v-if="getDateTimeSlotInCurrentCard(psychologist)"
                  >{{
                    $vuetify.lang.t(
                      "$vuetify.main.patientDashboard.ourPsychologists.on"
                    )
                  }}
                  {{ showCurrentDateSlot }}</span
                >
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </div>
    </div>
    <v-row justify="center" class="mt-8 mb-8">
      <div class="text-center">
        <v-pagination
          @next="page + 1"
          @previous="page - 1"
          v-model="page"
          :length="lastPage"
          :total-visible="8"
        ></v-pagination>
      </div>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

const showPsyhDescriptionModal = () =>
  import("../../UI/showPsyhDescriptionModal");

const showDateTimePicker = () => import("../../UI/showDateTimePickerModal");

export default {
  name: "patientPsychologist",
  components: { showPsyhDescriptionModal, showDateTimePicker },
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.ourPsychologists"),
    };
  },
  data() {
    return {
      attrs: {
        boilerplate: false,
        elevation: 4,
      },
      lastPage: null,
      showDescription: false,
      loading: false,
      isLoad: false,
      selection: 1,
      selected: null,
      currentCategoryBtn: null,
      selectedNewPsychologistByPatient: {},
      page: 1,
      prevPage: null,
      filter: "",
      prevFilter: "",
      psychologists: [],
      filters: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    getUserRole: function () {
      return this.user.role;
    },
    getCurrentTimeSlotId: function () {
      return this.user.time_slots[0] ? this.user.time_slots[0].slots[0].id : "";
    },
    showCurrentDateSlot: function () {
      return this.selectedNewPsychologistByPatient.timeSlot
        ? this.selectedNewPsychologistByPatient.timeSlot.date.translated
        : "";
    },
    showCurrentTimeSlot: function () {
      return this.selectedNewPsychologistByPatient.timeSlot
        ? this.selectedNewPsychologistByPatient.timeSlot.slot.time
        : "";
    },
  },
  watch: {
    page() {
      this.handleLoadPsychologists(this.page, this.filter);
    },
    filter() {
      this.handleLoadPsychologists(this.page, this.filter);
    },
  },
  created() {
    this.handleLoadPsychologists(this.page, "");
  },
  mounted() {
    this.$store.dispatch("user/getPsychologistFilter").then((response) => {
      this.filters = response.data.data;
    });
  },
  methods: {
    setConsultingExperienceText(consultingExperience) {
      if (
        consultingExperience === 11 ||
        consultingExperience === 12 ||
        consultingExperience === 13 ||
        consultingExperience === 14
      ) {
        return this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.years"
        );
      } else if (consultingExperience % 10 === 1) {
        return this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.year"
        );
      } else if (
        consultingExperience % 10 === 2 ||
        consultingExperience % 10 === 3 ||
        consultingExperience % 10 === 4
      ) {
        return this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.years2"
        );
      } else {
        return this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.years"
        );
      }
    },
    savePsychologistAndTimeSlot(psychologistInfo = {}) {
      this.selectedNewPsychologistByPatient = psychologistInfo;
    },
    async saveNewPsychologistOnServer(psychologistId) {
      // let payload = {};

      if (
        psychologistId &&
        Object.keys(this.selectedNewPsychologistByPatient).length === 0
      ) {
        if (this.getCurrentTimeSlotId) {
          await this.$store
            .dispatch("user/cancelTimeSlotByPatient", {
              id: this.getCurrentTimeSlotId,
            })
            .then((response) => {
              this.getNotificationForResponse(response);
            });
        }

        await this.$store
          .dispatch("user/updateProfile", {
            questionnaire: { profile_id: psychologistId },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.status === 200) {
                this.getNotificationForResponse(response);
              }
            }
          });
      }

      if (Object.keys(this.selectedNewPsychologistByPatient).length > 0) {
        if (this.getCurrentTimeSlotId) {
          await this.$store
            .dispatch("user/cancelTimeSlotByPatient", {
              id: this.getCurrentTimeSlotId,
            })
            .then((response) => {
              if (response.status === 200) {
                this.getNotificationForResponse(response);
              }
            });
        }

        await this.$store
          .dispatch("user/updateProfile", {
            questionnaire: { profile_id: psychologistId },
          })
          .then((response) => {
            if (response.status === 200) {
              this.getNotificationForResponse(response);
            }
          });

        await this.$store
          .dispatch("user/bookTimeSlotByPatient", {
            id: this.selectedNewPsychologistByPatient.timeSlot.slot.id,
          })
          .then((response) => {
            if (response.status === 200) {
              this.getNotificationForResponse(response);
            }
          });
      }
      this.selectedNewPsychologistByPatient = {};
      await this.$store.dispatch("user/getProfile", {});
    },
    handleFilters(id, event) {
      this.filter = id;

      this.vchipCategory(id, event);
    },
    handleLoadPsychologists(page, filter) {
      this.isLoad = true;

      if (this.prevFilter !== filter && this.page > 1) {
        page = 1;
        this.page = 1;
      }

      this.$store
        .dispatch("user/getPsychologists", { page, filter })
        .then((response) => {
          this.lastPage = response.data.meta.last_page;
          this.psychologists = response.data.data;
        })
        .finally(() => {
          this.isLoad = false;
        });

      this.prevPage = page;
      this.prevFilter = this.filter;
    },
    submitCategory() {
      this.prevBtn = null;

      if (this.currentCategoryBtn) {
        this.currentCategoryBtn.classList.toggle("activeColor");
        this.selected = null;
      }

      this.isLoad = true;
    },
    vchipCategory(id, event) {
      if (this.prevBtn) {
        this.prevBtn.classList.remove("activeColor");
      }

      if (event.target.tagName === "SPAN") {
        if (event.target.parentElement.tagName === "SPAN") {
          this.prevBtn = event.target.parentElement;
          event.target.parentElement.classList.toggle("activeColor");
        } else {
          this.prevBtn = event.target;
          event.target.classList.toggle("activeColor");
        }
      }

      this.currentCategoryBtn = this.prevBtn;
      this.selected = {
        id: id,
      };
    },
    reserve() {
      this.loading = true;
      this.savePsychologistAndTimeSlot();
    },
    getNotificationForResponse(response, timer = 3500, position = "top-right") {
      Vue.swal({
        target: document.getElementById("main"),
        text: response.data.message,
        icon: "success",
        timer: timer,
        toast: true,
        position: position,
        showConfirmButton: false,
      });
    },
    getDateTimeSlotInCurrentCard(currentPsychologist) {
      return (
        currentPsychologist.id === this.selectedNewPsychologistByPatient.id
      );
    },
  },
  filters: {
    shorten: (val, words = 10) => val.split(" ").slice(0, words).join(" "),
  },
};
</script>

<style lang="scss" scoped>
.aside-bar {
  max-width: 400px;
}

.activeColor {
  color: #ffffff;
  background-color: $primary;
  font-weight: 800;
}

.sticky-btn-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 50px;
  height: 42.5%;
}

.sticky-btn {
  position: sticky;
  left: 34px;
  top: 155px;
}

.border-rounded {
  border-radius: 50% !important;
}

.full-width {
  width: 100%;
}

.text__primary {
  color: $primary;
}
</style>
